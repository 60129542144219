export var SearchList = [
  { labe: '公司名称', code: 'company', type: 'input', placeholder: '编码/名称' },
  { labe: '开票方', code: 'drawer', type: 'input', placeholder: '编码/名称' },
  { labe: '网上发票号', code: 'code', type: 'input', placeholder: '编码/名称' },
  {
    labe: '到期日',
    code: 'maturityDate',
    type: 'daterange'
  }
]
export var SearchData = {
  companyName: '',
  drawer: '',
  onlineInvoiceNumber: '',
  // maturityDate: '',
  startTime: '',
  endTime: ''
}

export var tableField = [
  {
    label: '序号',
    code: '',
    type: 'function',
    width: '60',
    handle: (index) => {
      return index + 1
    }
  },
  { label: '会计年度', code: 'accountingYear', type: 'input', width: '90' },
  // { label: '公司名称', code: 'companyName', type: 'input', width: '100' },
  {
    label: '公司名称',
    code: 'companyName',
    type: 'function',
    width: '140px',
    handle (index, row) {
      return `${row.companyCode}-${row.companyName}`
    }
  },
  {
    label: '开票方',
    code: 'drawerName',
    type: 'function',
    width: '120px',
    handle (index, row) {
      return `${row.drawerCode}-${row.drawerName}`
    }
  },
  // { label: '开票方编码', code: 'drawerCode', type: 'input', width: '120' },
  // { label: '开票方', code: 'drawerName', type: 'input', width: '' },
  { label: '网上发票号', code: 'onlineInvoiceNumber', type: 'input', width: '200' },
  { label: '会计凭证编号', code: 'accountingDocumentCode', type: 'input', width: '120' },
  { label: '本位币', code: 'standardCoin', type: 'input', width: '' },
  { label: '应付金额', code: 'amountPayable', type: 'amount', align: 'right', width: '' },
  { label: '到期日', code: 'maturityDate', type: 'input', width: '120' },
  { label: '已申请金额', code: 'amountApplied', type: 'amount', align: 'right', width: '120' },
  { label: '预付合计', code: 'prepayTotal', type: 'amount', align: 'right', width: '' },
  { label: '调整金额', code: 'adjustmentAmount', type: 'amount', align: 'right', width: '' },
  { label: '剩余可申请金额', code: 'remainingApplicableAmount', type: 'amount', align: 'right', width: '120' }
]

export var tableData = []
