<template>
<!-- 采购应付账款 -->
    <div class="list">
      <list-search :childData="childData" @onSearch="search"  @clearSearch="search"></list-search>
      <global-table :tableField="tableField"  :tableData="tableData" :paginationData="paginationData" @pageChange="pageChange"></global-table>
    </div>
</template>

<script>
import ListSearch from '@/components/actionList/ListSearch.vue'
import GlobalTable from '@/components/actionList/GlobalTable.vue'
import { request } from '@/assets/js/http.js'
import dayjs from 'dayjs'
import { SearchList, SearchData, tableField, tableData } from './js/accountsPayable'
export default {
  components: { ListSearch, GlobalTable },
  name: 'AccountsPayable',
  data () {
    var pageSize = parseInt((document.documentElement.clientHeight - 280) / 42)
    if (pageSize < 10) {
      pageSize = 10
    }
    return {
      tableField: tableField,
      tableData: tableData,
      menudata: [],
      searchdata: SearchData,
      paginationData: { pageSize: pageSize, total: 1 },
      pageSize: pageSize,
      pageNum: 1,
      childData: { searchList: SearchList, searchData: SearchData }
    }
  },
  created () {
    this.accountsPayableList()
  },
  methods: {
    accountsPayableList () {
      request('/api/reconciliation/pay/payOrderList', 'get', { pageNum: 1, pageSize: this.pageSize }).then((res) => {
        if (res.code === '200') {
          this.tableData = res.data.records
          this.paginationData = { pageSize: this.pageSize, total: res.data.total }
        }
      })
    },
    dateFormate: function (data) {
      return dayjs(data).format('YYYY-MM-DD')
    },
    search: function (data) {
      data.pageNum = this.pageNum
      data.pageSize = this.pageSize
      this.searchdata = { ...data }
      delete this.searchdata.maturityDate
      if (data.maturityDate) {
        this.searchdata.endTime = this.dateFormate(data.maturityDate[1])
        this.searchdata.startTime = this.dateFormate(data.maturityDate[0])
      }
      request('/api/reconciliation/pay/payOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
          this.paginationData = { pageSize: this.pageSize, total: Response.data.total }
        }
      })
    },
    pageChange (data) {
      this.searchdata.pageNum = data
      this.searchdata.pageSize = this.pageSize
      request('/api/reconciliation/pay/payOrderList', 'get', this.searchdata).then((Response) => {
        if (Response.code === '200') {
          this.tableData = Response.data.records
        }
      })
    }
  }
}
</script>
<style scoped lang="scss">
@import '@/assets/css/tablePadding.scss';
</style>
